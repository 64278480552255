/* @tailwind base;
@tailwind components;
@tailwind utilities; */
/* @import '../node_modules/flatpickr/dist/flatpickr.min.css'; */
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';
@import "react-datepicker/dist/react-datepicker.css";
@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  --airco2-blue-color: #8DD4BD;
  --airco2-orange-color: #F19B7F;
  --airco2-grey-color: #878787;
  --airco2-white-color: #ffffff;
  --airco2-blue-color-tr: rgb(141, 212, 189, 0.9);
  --bs-link-hover-color: #F19B7F
}

/* Fuentes */
@font-face {
  font-family: 'openBold';
  src: url('./fonts/openSans/OpenSans-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'openReg';
  src: url('./fonts/openSans/OpenSans-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'openLight';
  src: url('./fonts/openSans/OpenSans-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'openSemi';
  src: url('./fonts/openSans/OpenSans-Semibold.ttf');
  font-display: swap;
}

/* */


/* PALETA DE ESTILOS*/
.bg-airco2-principal {
  background-color: var(--airco2-blue-color) !important;
}

.bg-airco2-principalHeaderFooter {
  background-color: var(--airco2-blue-color-tr) !important;

}

.text-airco2-principal {
  color: var(--airco2-blue-color);
}

.text-airco2-secondary {
  color: var(--airco2-orange-color);
}

a.text-airco2-principal:hover,
a.text-airco2-filter-content:hover,
a.text-airco2-general:hover {
  color: var(--airco2-orange-color) !important;
}



.text-airco2-general {
  color: var(--airco2-grey-color);
  font-family: 'openLight'
}

.text-arico2-footerHeader {
  color: var(--airco2-white-color);
  font-family: 'openReg';
  font-size: 14px
}

.text-arico2-HeaderAuth {
  color: var(--airco2-white-color);
  font-family: 'openBold';
  font-size: 14px
}

.text-arico2-HeaderAuth:hover,
.text-arico2-footerHeader:hover {
  color: var(--airco2-grey-color);

}

.text-airco2-home-titleSlider {
  color: var(--airco2-white-color);
  font-size: 35px;
  font-family: 'openLight'
}

.text-airco2-home-title {
  color: var(--airco2-blue-color);
  font-size: 30px;
  font-family: 'openLight'
}

.text-airco2-home-subtitleSlider {
  color: var(--airco2-white-color);
  font-size: 22px;
  font-family: 'openLight'
}

.text-airco2-home-subtitle {
  color: var(--airco2-grey-color);
  font-size: 17px;
  font-family: 'openLight'
}

.text-airco2-filter-title {
  color: var(--airco2-grey-color);
  font-size: 13px;
  font-family: 'openBold'

}

.text-airco2-filter-content {
  color: var(--airco2-grey-color) !important;
  font-size: 12px;
  font-family: 'openReg'

}

.text-airco2-sidebar {
  color: var(--airco2-grey-color);
  font-family: 'openReg';
  font-size: 12px
}





.bg-airco2-general {
  background-color: var(--airco2-grey-color) !important;

}


.bg-airco2-secondary {
  background-color: var(--airco2-orange-color) !important;

}

/* .bg-airco2-secondary:hover{
  background-color: var(--airco2-grey-color) !important;
} */
button.bg-airco2-principal,
button.bg-airco2-general {
  color: var(--airco2-white-color);
  border-radius: 0.375rem;
}



button.bg-airco2-principal:hover,
button.bg-airco2-general:hover {
  background-color: var(--airco2-orange-color) !important;

}



button.bg-airco2-principal:disabled,
button.bg-airco2-general:disabled {
  background-color: var(--airco2-grey-color) !important;
}

Link.bg-airco2-principal:hover {
  background-color: var(--airco2-orange-color) !important;

}

.p-cabecera {
  padding-top: 88px;
}

.border-principal {
  border: 1px solid var(--airco2-blue-color) !important;
}

.border-general {
  border: 1px solid var(--airco2-grey-color) !important;
}

/* CheckBox personalizado */

label>input[type="checkbox"] {
  display: none;
}

label>input[type="checkbox"]+*:before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.5rem;
  border-radius: 10%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: var(--airco2-blue-color);
  flex-shrink: 0;
}

/* label > input[type="checkbox"]:checked + * {
  color: teal;
} */
label>input[type="checkbox"]:checked+*:before {
  content: "✓";
  color: white;
  text-align: center;
  background: var(--airco2-blue-color);
  border-color: var(--airco2-blue-color)
}

/* Fin checkbox personalizado */


label>input[type="radio"] {
  display: none;
}

label>input[type="radio"]+*:before {
  content: "";
  display: flex;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: var(--airco2-blue-color);
  flex-shrink: 0;
}

label>input[type="radio"]:checked+*:before {
  content: "x";
  color: white;
  justify-content: center;
  align-items: center;
  background: var(--airco2-blue-color);
  border-color: var(--airco2-blue-color)
}

/* Formulario registro y usuario*/
.form-user-data {
  /* border-color:  var(--airco2-blue-color); */
  /* border-color:  var(--airco2-grey-color); */
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.125rem;
  border-width: 0.1px;
  --tw-border-opacity: 0.5;
  background-color: transparent;

}


option {
  color: var(--airco2-grey-color);
  font-family: 'openReg';
}

select option:hover {
  background-color: red !important;
}

/* PRIMERAS LINEAS COPIADAS */

.card-text-mk {
  opacity: 0;
}

.card-img-mk:hover .card-text-mk {
  opacity: 1;


}




@media (max-width: 700px) {
  .container-filtro-pc {
    display: none !important
  }

  .flecha-filter-pc {
    display: none !important;
  }


}

@media (min-width: 700px) {

  .flecha-filter-mvl {
    display: none !important;
  }


}

.sidebar-detail-content {
  position: sticky;
  top: 150px;


}

.list-objetivos-sost {
  width: 50vw;



}

@media only screen and (max-width: 980px) and (min-width: 768px) {
  .sidebar-detail-content {
    /* height: 420px !important; */
    top: 0 !important;
  }
}

.card-container {
  /* min-width: 20rem;
  max-width: 20rem;
  margin-top: 1%; */
  min-width: 22rem;
  max-width: 22rem;
  margin-top: 2%;
}

.card-container-map {
  min-width: 11rem;
  max-width: 11rem;
  margin-top: 2%;
}


.container-card-market {
  display: flex;
  flex-wrap: wrap;
  /* margin-left:3vw ;
margin-right: 1vw; */
  /* 
  justify-content: space-between; */
  /* gap: 0.2rem */

  /* justify-content: center;
  gap:2rem; */

  justify-content: space-evenly;

}

/* @media (max-width: 768px) {
  .container-card-market {

    justify-content: space-evenly;
  }
} */

.card-img-catalogo {
  height: 22rem;
  min-height: 22rem;
}

.card-img-catalogo-map {
  height: 9rem;
}

label {


  display: inline-block;
  margin-bottom: .5rem;

}

.form-checkbox,
.form-radio {
  color: rgb(30 41 59);
}



@media only screen and (max-width: 1023px) {
  #divFlotante {
    display: flex;
    top: 91%;
    z-index: 500;
    /* bottom: 0px; */
    position: sticky;
    align-items: center;
    height: 70px;
    background: var(--airco2-blue-color);
    color: white;
    opacity: 0.9;
    justify-content: center;
    /* width: 100%; */
    /* margin-left: 7.8vw;
    margin-right: 1vw; */

  }



}

@media (min-width: 1023px) {
  #divFlotante {
    display: none;
  }

  .list-objetivos-sost {
    width: 28vw;

  }


  /* .f-containter-content {

    display: flex;
    justify-content: center;



  } */



  .category-card-catalogo {
    border-top-left-radius: 0.125rem;
  }



  .card-carrito {

    display: flex;
    flex-direction: row;

  }

}

.leaflet-container {
  width: 100%;
  height: 90vh;
}

.card-carrito {
  width: 100px;
}

.container-img-shop {
  display: none;

}

.container-forestype {
  opacity: 0;
}

.container-category {
  opacity: 0;
}

.input-change-value {

  /* width: 15vw; */
  width: 10vw;

}



.modal-carrito {
  min-width: 30vw;
  max-width: 55vw;



}


@media (min-width: 768px) {

  .img-fondo-shop {
    width: 180px;
    height: 100px;

  }

  .container-category {
    opacity: 0;
  }

  .container-forestype {
    opacity: 0;
  }

}

.input-change-value {

  /* width: 10vw; */
  width: 6vw;


}





@media (min-width: 981px) {
  .container-img-shop {
    display: inline-block
  }

  .img-fondo-shop {
    width: 250px;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


  }

  .category-forestype {
    display: none;
  }

  .input-change-value {

    /* width: 10vw; */
    width: 6vw;

  }

  .container-category {
    opacity: 1;
  }

  .container-forestype {
    opacity: 1;
  }



  .modal-carrito {
    min-width: 25vw;
    max-width: 30vw;



  }

}



input:disabled {
  background: #ccc;
}

button:disabled {
  background: #ccc;
}

.minus-mt20-mb10 {
  margin-top: -20px;
  margin-bottom: -10px;
}

.btnDisabled {

  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.125rem;
  border-width: 1px;
  border-color: transparent;
  font-weight: 500;
  line-height: 1.375;


}

.pic-carousel1 {

  width: 100%;
  height: calc(60vh);
  background-image: url(/src/images/Slide01.webp);
  background-size: cover;
  background-position: center;
  /* display: flex; */
}

.pic-carousel2 {

  width: 100%;
  height: calc(60vh);
  background-image: url(/src/images/Slide02.webp);
  background-size: cover;
  background-position: center;


}

.pic-carousel3 {

  width: 100%;
  height: calc(60vh);
  background-image: url(/src/images/Slide03.webp);

  background-size: cover;
  background-position: center;

}

.Carousel__Item-sc-hyhecw-5 {
  justify-self: center;
}

.iQHHox {
  padding-top: 1vh;
}

.pt-header {
  padding-top: 88px
}

/* @media (max-width: 798px) {
  .pt-header {
    padding-top: 50px
  }
} */



.z-99 {
  z-index: 999;
}

.containerContador {
  display: flex;
  flex-wrap: wrap;


}


.contador {
  font-weight: bold;
}

.divContainer {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;


}

@media (max-width: 700px) {

  .jjfwLM>.card-container {
    min-width: 18.8rem;
    max-width: 18.8rem;


  }

}

/* Slider */
.fkPNhT .slider::-webkit-slider-thumb,
.slider::-moz-range-thumb {
  background: var(--airco2-blue-color) !important;
}

.fkPNhT .slider {
  background: var(--airco2-grey-color) !important;
  height: 3px !important;
}

.fkPNhT {
  color: var(--airco2-grey-color) !important;
  margin: 0 !important;
  margin-bottom: 1rem;
}

.accordion-button:not(.collapsed) {
  color: var(--airco2-grey-color) !important;

}


.accordion {
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23878787%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%238DD4BD%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
  --bs-accordion-btn-focus-border-color: var(--airco2-blue-color);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgb(141, 212, 189, 0.5) !important;
  --bs-accordion-active-bg: rgb(141, 212, 189, 0.2) !important
}

.accordion-button {
  color: var(--airco2-grey-color) !important;
  font-weight: 600;


}

.accordion-item {
  color: var(--airco2-grey-color) !important;
}

.ReactModal__Content--after-open {
  margin-top: 7vh !important;
}

#custom-list-container ul {
  list-style: disc !important;
}

.iconosCard {
  left: 170px;
  width: 120px
}

@media (min-width: 701px) {

  .iconosCard {
    left: 220px;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

thead button.ascending::after {
  content: '⬇️';
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: '⬆️';
  display: inline-block;
  margin-left: 1em;
}


.table-container {
  overflow-x: auto; 
  width: 100%;
  table-layout: fixed;
 
}

.table-striped {
  width: 100%;
  background-color:white;
  table-layout: auto;
}
.date-picker-container {
  display: flex;
  align-items: center;
  gap:5px;
  border: 1px solid#e9e2e2;
  padding:5px 
 
}

.date-picker {

  display: flex;
  flex-direction: column;


}

.date-picker label {
  margin-bottom: 1px;
  padding-left: 5px;

}

.date-picker-box{
  border: 0.5px solid #e9e2e2; 
 
}



.react-datepicker__month-container {
  background-color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: var(--airco2-blue-color-tr) !important;
  color: #fff; 
}

.react-datepicker__input-container input {
  padding-left: 5px; 
}

.react-datepicker__header {
  background-color: rgb(141, 212, 189, 0.6) !important;
  
}

.filterButtonListForest{
  height: 55px;
  min-width: 190px
  
}

.inputCatastro{
  min-width: 230px;
}
@media (max-width: 768px) {

  .filterButtonListForest{
    width: 313px;
  }

  .inputCatastro{
    width: 313px;
  }
}
@media (max-width: 634px) {
.filtrosContainer{
  justify-content: center !important;
}

}

.btn-sm{

  border-radius: 0.375rem !important;

}

